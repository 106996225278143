import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div id="footer">
      <h3>D&K</h3>
      <p className="separator"></p>
      <h4>2024.10.11.</h4>
    </div>
  );
};
